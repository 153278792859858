<!--
 * @Author: wh
 * @Date: 2020-06-28 16:47:56
 * @LastEditTime: 2020-06-28 19:32:08
 * @LastEditors: Please set LastEditors
 * @Description: team session
 * @FilePath: \Web\JT.Web\src\views\messageCenter\pages\page\components\dialogue\TeamSession.vue
-->
<template>
    <div>
        <ul>
            <li
                class="noData ng-scope"
                v-if="sessionlist.length<=0"
            >
                暂无最近联系人
            </li>
            <li
                v-for="(session, index) in sessionlist"
                :key="session.id"
                :sessionId="session.id"
                @click="enterChat(session, index)"
                :class="{'active': activeIndex===index}"
                class="team"
            >
                <div class="HeadPortrait">
                    <p>{{ session.name|getLastCharacter }}</p>
                </div>
                <div class="information">
                    <div class="infoTitle">
                        <p :title="session.name">
                            <span>{{ session.name }}</span>
                        </p>
                        <p>{{ session.updateTimeShow }}</p>
                    </div>
                    <div class="infoContent">
                        <p class="fl">
                            {{ session.lastMsgShow }}
                        </p>
                        <b
                            v-show="session.unread > 0"
                            class="u-unread fr"
                        >{{ session.unread }}</b>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import util from '@/common/NIM/utils';
import config from '@/common/NIM/configs';
export default {
    data() {
        return {
            delSessionId: null,
            stopBubble: false,
            noticeIcon: config.noticeIcon,
            myPhoneIcon: config.myPhoneIcon,
            myGroupIcon: config.defaultGroupIcon,
            myAdvancedIcon: config.defaultAdvancedIcon,
            activeIndex: null,
        };
    },
    computed: {
        sysMsgUnread() {
            const temp = window.$globalHub.$store.state.sysMsgUnread;
            let sysMsgUnread = temp.addFriend || 0;
            sysMsgUnread += temp.team || 0;
            const customSysMsgUnread = window.$globalHub.$store.state.customSysMsgUnread;
            return sysMsgUnread + customSysMsgUnread;
        },
        userInfos() {
            return window.$globalHub.$store.state.userInfos;
        },
        myInfo() {
            return window.$globalHub.$store.state.myInfo;
        },
        myPhoneId() {
            return `${window.$globalHub.$store.state.userUID}`;
        },
        sessionlist() {
            const sessionlist = window.$globalHub.$store.state.sessionlist.filter(item => {
                item.name = '';
                item.avatar = '';
                if (item.scene === 'p2p') {
                    let userInfo = null;
                    if (item.to !== this.myPhoneId) {
                        userInfo = this.userInfos[item.to];
                    } else {
                        // userInfo = this.myInfo
                        // userInfo.alias = '我的手机'
                        // userInfo.avatar = `${config.myPhoneIcon}`
                        return false;
                    }
                    if (userInfo) {
                        item.name = util.getFriendAlias(userInfo);
                        item.avatar = userInfo.avatar;
                    }
                } else if (item.scene === 'team') {
                    let teamInfo = null;
                    teamInfo = window.$globalHub.$store.state.teamlist.find(team => {
                        return team.teamId === item.to;
                    });
                    if (teamInfo) {
                        item.name = teamInfo.name;
                        item.avatar
              = teamInfo.avatar
              || (teamInfo.type === 'normal'
                  ? this.myGroupIcon
                  : this.myAdvancedIcon);
                    } else {
                        item.name = `群${item.to}`;
                        item.avatar = item.avatar || this.myGroupIcon;
                    }
                }
                const lastMsg = item.lastMsg || {};
                if (lastMsg.type === 'text') {
                    item.lastMsgShow = lastMsg.text || '';
                } else if (lastMsg.type === 'custom') {
                    item.lastMsgShow = util.parseCustomMsg(lastMsg);
                } else if (
                    lastMsg.scene === 'team'
          && lastMsg.type === 'notification'
                ) {
                    item.lastMsgShow = util.generateTeamSysmMsg(lastMsg);
                } else if (util.mapMsgType(lastMsg)) {
                    item.lastMsgShow = `[${util.mapMsgType(lastMsg)}]`;
                } else {
                    item.lastMsgShow = '';
                }
                if (item.updateTime) {
                    item.updateTimeShow = util.formatDate(item.updateTime, true);
                }
                return item;
            });
            const teamsession = sessionlist.filter(item => item.scene === 'team' );
            return teamsession;
        },
    },
    methods: {
        enterChat(session, index) {
            this.$emit('clickSession', session.id, session);
            this.activeIndex = index;
        },
    },
};
</script>
<style>
</style>
